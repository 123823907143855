import { isLoggedIn } from '@commonstock/common/src/auth'
import GlobalFeed from 'src/scopes/feed/GlobalFeed'
import createLayout from 'src/scopes/layout/createLayout'
import { Screen } from 'src/scopes/analytics/constants'

type Props = {}
export default function FeedScreen({}: Props) {
  return <GlobalFeed />
}

FeedScreen.getLayout = createLayout({ publicScreen: true, hasSingleColumn: () => isLoggedIn() })
FeedScreen.screen = Screen.Home
